import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class ActivationService{
	apiUrl = baseUrl

	async loadPins (lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-pin/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async generatePins (totalPin) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/generate-pin/${totalPin}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
	async markAsTaken (id) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/mark-as-taken/${id}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async loadMember (lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/activated-member/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}
const activationService = new ActivationService()
export { activationService }